import React, { useEffect } from "react";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import getMerchantTransaction, {
  getMerchantGroupByUser,
} from "../services/reports";
import ReportPdf from "./ReportPdf";
import CustomSelect from "../utils/CustomSelect";
import { components } from "react-select";
import makeAnimated from "react-select/animated";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);
const animatedComponents = makeAnimated();

export default function TotalDailySalesReport() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [totalSales, setTotalSales] = React.useState(null);
  const [dateFrom, setDateFrom] = React.useState(null);
  const [dateTo, setDateTo] = React.useState(null);
  const [merchants, setMerchants] = React.useState([{}]);
  const [selectedMerchants, setSelectedMerchants] = React.useState([]);
  const [selectedPaybackPeriod, setSelectedPaybackPeriod] = React.useState([]);
  const [filtered, setFiltered] = React.useState(false);

  const paybackperiod = [
    { value: "1", label: "1 Month" },
    { value: "2", label: "2 Months" },
    { value: "3", label: "3 Months" },
    { value: "4", label: "4 Months" },
    { value: "6", label: "6 Months" },
    { value: "10", label: "10 Months" },
  ];
  useEffect(() => {
    getMerchantGroupByUser()
      .then((response) => {
        const { data } = response;
        console.log(data);
        const formattedData = data.map((item) => {
          return {
            value: item.id,
            label: item.Name,
          };
        });
        setMerchants(formattedData);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (!totalSales) return;
    setFiltered(true);
  }, [totalSales]);
  const validate = (data) => {
    if (!data || data.length === 0) return false;
    return true;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!dateFrom || !dateTo) {
      alert("date is empty");
      setIsLoading(false);
    } else {
      const merchantIds = selectedMerchants.map((item) => item.value);
      const months = selectedPaybackPeriod.map((item) => item.value);
      getMerchantTransaction(merchantIds, dateFrom, dateTo, months)
        .then((res) => {
          setIsLoading(false);
          if (validate(res.data)) setTotalSales(res.data);
          console.log(res.data);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  const handleChange = (selected) => {
    setSelectedMerchants(selected);
  };
  const handleSelectAll = (selected) => {
    setSelectedPaybackPeriod(selected);
  };
  return (
    <div>
      <div>
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <Card className="form-wrapper mt-4">
                <Card.Header style={{ backgroundColor: "#F7F7F8" }}>
                  <Card.Title as="h4" className="text-center m-3 heading">
                    Daily Transaction Report
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md="12">
                        <Form.Group>
                          <label className="requiredelement">Merchants</label>
                          <CustomSelect
                            options={merchants} // Options to display in the dropdown
                            // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{
                              Option,
                              MultiValue,
                              animatedComponents,
                            }}
                            onChange={handleChange}
                            allowSelectAll={true}
                            value={selectedMerchants}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <Form.Group>
                          <label className="requiredelement">
                            Payback Period
                          </label>
                          <CustomSelect
                            options={paybackperiod} // Options to display in the dropdown
                            // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{
                              Option,
                              MultiValue,
                              animatedComponents,
                            }}
                            onChange={handleSelectAll}
                            allowSelectAll={true}
                            value={selectedPaybackPeriod}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group>
                          <label className="requiredelement">Date From</label>
                          <Form.Control
                            required
                            placeholder="##"
                            type="date"
                            name="Title"
                            onChange={(e) => {
                              setDateFrom(e.target.value);
                            }}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group>
                          <label className="requiredelement">Date To</label>
                          <Form.Control
                            required
                            placeholder="##"
                            type="date"
                            name="Title"
                            onChange={(e) => {
                              setDateTo(e.target.value);
                            }}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please provide a value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="text-center justify-content-center mt-2">
                      <Col md="12">
                        <div className="button-wrapper">
                          <Button
                            className="btn-fill res-size"
                            type="submit"
                            style={{
                              backgroundColor: "#3AAB7B",
                              border: "none",
                            }}
                            disabled={isLoading}
                          >
                            {isLoading ? "Filtering..." : "Filter"}
                          </Button>

                          {!isLoading && filtered && (
                            <ReportPdf data={totalSales} />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
