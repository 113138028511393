import address from "./address";
import axios from "axios";

export default function addClient(formData) {
  return axios.post(address + "/api/public/clients/createClient", formData);
}
export function getActiveClientList() {
  return axios.get(address + "/api/public/clients/getActiveClientList");
}

export function getNextId() {
  return axios.get(address + "/api/public/clients/getNextM_Id");
}

export function getNextNK_Id() {
  return axios.get(address + "/api/public/clients/getNextNM_Id");
}

export function addClientImage(formData) {
  return axios.post(
    address + "/api/public/clientProfilePicture/createImage",
    formData,
    {
      onUploadProgress: (data) => {
        console.log(Math.round((data.loaded / data.total) * 100));
      },
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

export function addSalarySlips(formData) {
  return axios.post(
    address + "/api/css/add",
    formData,
    {
      onUploadProgress: (data) => {
        console.log(Math.round((data.loaded / data.total) * 100));
      },
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

export function addBankStatement(formData) {
  return axios.post(
    address + "/api/cbs/add",
    formData,
    {
      onUploadProgress: (data) => {
        console.log(Math.round((data.loaded / data.total) * 100));
      },
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}
