import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";
import Navigation from "./components/Navigation";
// import Contact from "./components/Contact";
import Home from "./pages/Home";
import "./App.css";
import ClientForm from "./pages/ClientForm";
import ClientNoboForm from "./pages/ClientNoboForm";
import LoginForm from "./pages/Login.js";
import Reports from "./pages/Reports.jsx";
// import checkUser from './services/auth';
import Footer from "./components/Footer";
const App = () => {
  const theme = createTheme();
  // const [ pathname ] = React.useState( useLocation().pathname.substring( 1 ) );
  return (
    <div className="page-container">
      <div className="content-wrap">
        <ThemeProvider theme={theme}>
          <Helmet>
            <meta charSet="utf-8" />
          </Helmet>
          <Router>
            <Navigation />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/Home" element={<Home />} />
              <Route path="/clientForm" element={<ClientForm />} />
              <Route path="/clientNoboForm" element={<ClientNoboForm />} />
              <Route path="/login" element={<LoginForm />} />
              <Route path="/report" element={<Reports />} />
              {/* <Route path="/" element={<Forms />} /> */}
              {/* <Route exact path="/ClientNoboForm" element={<ClientNoboForm />} /> */}
            </Routes>
          </Router>
        </ThemeProvider>
      </div>
      <Footer />
    </div>
  );
};

export default App;
