import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, IconButton, Grid, Box } from "@mui/material";
import popupImg from "../assets/img/image1.jpeg";
import popupImg2 from "../assets/img/image2.jpeg";

const ImagePopup = () => {
  const [open, setOpen] = useState(true);

  // Close the dialog
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Show the popup as soon as the page loads
    setOpen(true);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      sx={{ padding: 0 }}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
        }}
      >
        <i
          className={`fa fa-times`}
          aria-hidden="true"
          style={{ fontSize: "18px" }}
        />
      </IconButton>
      <DialogContent sx={{ padding: 0 }}>
        {" "}
        {/* Remove padding from the DialogContent */}
        <Grid container spacing={0}>
          {" "}
          {/* Remove spacing between the images */}
          <Grid item xs={6}>
            <Box
              component="img"
              src={popupImg}
              alt="Left Image"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover", // Ensures the image covers the container without distortion
                borderRadius: "0px",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Box
              component="img"
              src={popupImg2}
              alt="Right Image"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover", // Ensures the image covers the container without distortion
                borderRadius: "0px",
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ImagePopup;
