import React from "react";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
} from "@react-pdf/renderer";
import {
  DataTableCell,
  TableBody,
  Table,
  TableCell,
  TableHeader,
} from "@david.kucsai/react-pdf-table";

export default function ReportPdf({ data }) {
  console.log(data);
  const [isClicked, setIsClicked] = React.useState(false);
  const sendTotal = new Date().getDate() > 27 ? true : false;
  const pdf = (
    <Document>
      <Page>
        {sendTotal && (
          <View textAlign={"center"}>
            <Text>
              Please send your total of this month to info@merpol.org stating
              your company name
            </Text>
          </View>
        )}
        <Table data={data}>
          <TableHeader textAlign={"center"}>
            <TableCell>Merchant Name</TableCell>
            <TableCell>Client Name</TableCell>
            <TableCell>Client Code</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Item Description</TableCell>
            <TableCell>Payback Period</TableCell>
            <TableCell>Payback Period Types</TableCell>
          </TableHeader>
          <TableBody textAlign={"center"}>
            <DataTableCell getContent={(r) => r.Merchant_Name} />
            <DataTableCell getContent={(r) => r.Client_Name} />
            <DataTableCell getContent={(r) => r.Client_Code} />
            <DataTableCell getContent={(r) => r.Date} />
            <DataTableCell getContent={(r) => r.Amount} />
            <DataTableCell getContent={(r) => r.Item_Description} />
            <DataTableCell getContent={(r) => r.Period} />
            <DataTableCell getContent={(r) => r.PaybackPeriod_Types} />
          </TableBody>
        </Table>
      </Page>
    </Document>
  );
  const handleDownloadClick = () => {
    setIsClicked(true);

    // Reset isClicked after one second
    setTimeout(() => {
      setIsClicked(false);
    }, 1000);
  };

  const iconStyle = {
    color: isClicked ? "grey" : "#3aab7b",
  };

  return (
    <div style={{ textAlign: "center" }}>
      <PDFDownloadLink document={pdf} fileName={"Transcation_Report.pdf"}>
        {({ blob, url, loading, error }) => (
          <div>
            <button
              onClick={handleDownloadClick}
              disabled={isClicked}
              style={{
                border: "none",
                background: "none",
              }}
            >
              <i
                className={`fa fa-download`}
                aria-hidden="true"
                style={iconStyle}
              />
            </button>
          </div>
        )}
      </PDFDownloadLink>
    </div>
  );
}
