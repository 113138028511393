import React from "react";
import "./Footer.css";
export default function Footer() {
  return (
    <div className="main-footer">
      <div className="container">
        <div className="container text-center">
          <p>
            &copy; 2024 all rights reserved by
            <span
              style={{ marginLeft: "5px", color: "#3AAB7B", fontWeight: "500" }}
            >
              Merpol
            </span>
          </p>
        </div>
        <div className="container text-center">
          <p>
            We will not, in any circumstances, share your personal information
            with other individuals or organizations without your permission,
            including public organizations, corporations or individuals, except
            when applicable by law. We do not sell, communicate or divulge your
            information to any mailing lists. The only exception is if the law
            or a court order compels us to. We will share your information with
            government agencies if they need or request it.
          </p>
        </div>
      </div>
    </div>
  );
}
