import { getToken } from "./auth";
import address from "./address";
import axios from "axios";



export default function getMerchantTransaction(merchants, dateFrom, dateTo, months) {
    const token = getToken();
    if (!token) return "token not found";
    return axios.get(address + "/api/reports/merchantTransactionLanding/" + merchants + "&" + dateFrom + "&" + dateTo + "&" + months, {
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    });
}

export function getMerchantGroupByUser() {
    const token = getToken()
    if (!token) return "Authentication Fail Sign In agian"
    return axios.get(address + '/api/user/getMerchantGroupByUser', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    })
}